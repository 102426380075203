import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './components/Login'
import Dashboard from './components/Dashboard'
import Notfound from './components/Notfound'
import Trackbenefit from './components/Trackbenefit'
import Welcomeletter from './components/Welcomeletter'
import Updatebiodata from './components/Updatebiodata'
import Viewbiodata from './components/Viewbiodata'
import Contactus from './components/Contactus'
import Changepassword from './components/Changepassword'
import Statement from './components/Statement'
import Livechat from './components/Livechat';



function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Dashboard} />
        <Route path='/login' component={Login}/> 
        <Route path='/home' component={Dashboard}/>
        <Route path='/live' component={Livechat}/>
        <Route path='/changepassword' component={Changepassword}/>
        <Route path='/statement' component={Statement}/>
        <Route path='/contact' component={Contactus}/>
        <Route path='/view' component={Viewbiodata}/>
        <Route path='/update' component={Updatebiodata}/>
        <Route path='/track' component={Trackbenefit}/>
        <Route path='/welcome' component={Welcomeletter}/>
        <Route path='/dashboard' component={Dashboard}/>
        <Route component={Notfound}/>
      </Switch>      
    </BrowserRouter>
  );
}

export default App;
